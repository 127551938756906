import React, { useState, useContext } from "react";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";
import { AVATAR } from "../src/Componentes/Image";
import { Row, Col, Container, Label, Button } from "reactstrap";
import { url } from "./Componentes/Services";
import StoreContext from "./Componentes/Store/Context";
import { useNavigate } from "react-router-dom";
function initialState() {
  return { usr_user: "", usr_pwd: "" };

}

async function login({ usr_user, usr_pwd }) {
  if (usr_user.length > 0 && usr_pwd.length > 0) {
    const dataBody = {
      usr_user,
      usr_pwd,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(dataBody),
    };
    const response = await fetch(url + "LoginController/login", requestOptions);
    const result = await response.json();
    if (result[0].count !== "0") {

      return { token: true, usr_perfil: result[0].usr_perfil, usr_id: result[0].usr_id, usr_nombre: result[0].usr_nombre, usr_sucursal: result[0].usr_sucursal, usr_print: result[0].usr_print,
        usr_ajustes: result[0].usr_ajustes,usr_factura:result[0].usr_factura,usr_inventario:result[0].usr_inventario,usr_cotizacion:result[0].usr_cotizacion,usr_articulo:result[0].usr_articulo,
        usr_reporte: result[0].usr_reporte ,
        usr_apartado:result[0].usr_apartado,
        usr_clientes:result[0].usr_clientes,
        usr_precio:result[0].usr_precio
      };
    } else {
      return { error: "Usuario o contraseña incorrectos" };
    }
  } else {
    return { error: "Campos estan vacios" };
  }
}

export default function Login() {
  const [values, setValues] = useState(initialState);
  const [error, setError] = useState(null);
  const { setToken, quitToken } = useContext(StoreContext);

  const history = useNavigate();
  const dataUrl = {
    1: "/user",
    2: "/user",
    3: "/user"
  };

  function onChange(event) {
    const { value, name } = event.target;

    setValues({
      ...values,
      [name]: value
    });
    setError(null);
  }

  async function onSubmit(event) {
    event.preventDefault();
    const { token, usr_perfil, usr_id, usr_nombre, usr_sucursal, usr_print, usr_ajustes,usr_factura,usr_inventario,usr_cotizacion,usr_articulo,usr_reporte,usr_apartado,usr_clientes, error,usr_precio } = await login(values);
    if (token) {
      quitToken();
      setToken({ token, usr_perfil, usr_id, usr_nombre, usr_sucursal, usr_print, usr_ajustes,usr_factura,usr_inventario,usr_cotizacion,usr_articulo,usr_reporte,usr_apartado,usr_clientes,usr_precio });
      return  usr_perfil ==="3"? history(dataUrl[usr_perfil] +"/factura"): history(dataUrl[usr_perfil] +"/home");
    }
    setError(error);
    setValues(initialState);
  }
  return (
    <Container>
      <Row><Col xs={12} sm={12}>
        <Row className="mt-5">
          <Col xs={4}></Col>
          <Col xs={4} className="text-center">
            <img src={AVATAR} className="img-thumbnail text-center" alt="..." />
          </Col>
          <Col xs={4}></Col>
        </Row>
        <Row className="mt-5">
          <Col xs={1} sm={4}></Col>
          <Col xs={10} sm={4}>
            <form onSubmit={onSubmit}>
              <MDBInput className="mb-4" type="text" id="user" label="Usuario" name="usr_user" onChange={onChange} value={values.usr_user} />
              <MDBInput className="mb-4" type="password" id="password" label="Contraseña" name="usr_pwd" onChange={onChange} value={values.usr_pwd} />
              {error && <div className="user-login__error font-weight-bold"><Label className="text-danger font-weight-bold">{error}</Label></div>}
              <MDBBtn type="submit" block>
                Iniciar sesión
              </MDBBtn>
            </form>
          </Col>
          <Col xs={1} sm={4}></Col>
          <Col sm={12} className="text-center mt-4">
            <Label>
              Desarrollado por www.mtechnologypanama.com
            </Label>
          </Col>
        </Row>
      </Col></Row>
    </Container>
  );
}
