import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment";
import $, { data } from 'jquery';
import useScanDetection from 'use-scan-detection';
import { Container, Label, Row, Col } from "reactstrap";
import {SEARCH_IMAGE,ADD_IMAGE,EDIT_IMAGE,EXIT_IMAGE,LOGO,PRECIO_IMAGE,KNIFE_IMAGE, NO_IMAGE} from "./../Componentes/Image";
import StoreContext from "./../Componentes/Store/Context";
import {MDBBtn,MDBInput,MDBIcon,  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText} from "mdb-react-ui-kit";
import {Button} from '@mui/material';
import { validate } from 'react-email-validator';
import {STATES_FAC_FACTURA} from "../Componentes/States"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {ListaClienteModal,ClienteModal,PagarModal,ListaItemModal,EnviadoModal,AnulacionModal,ListaFacModal,ListaCotizacion,ListaCreditos } from "./../Componentes/Modal";
import {put_orden_detalle,put_delete_orden_detalle,put_sts_ordenes,get_ord_ordenes_mesas,get_ord_ordenes,get_res_restaurante,get_cr_credito_detalle_byIdentificador,print_recibo_pago,post_crp_credito_pago,get_cr_credito_detalle,get_crp_credito_pago,get_cr_credito,get_caja_n,get_cli_cliente,get_articulo_by_code,get_frm_pago,get_compania,post_cli_cliente,put_cli_cliente,get_list_items,get_cot_cotizacion_list,get_cot_cotizacion_fac,
      get_report_x,print_report_x,get_list_anulacion,get_list_fac_imprimir,get_list_itemsFactura,get_frm_pago_imprimir_fac,delete_crp_credito_pago,
      print_recibo_pagoCopia,imgURLocalImage} from "../Componentes/Services";
import {FIND_DESC,COPIA_FACTURA,SAVE_FACTURA,ANULAR_FACTURA,PUT_CLIENTE, FOOTER_FACTURA,roundNumber, SAVE_ORDEN} from "./../Componentes/Util";
import {DATATABLE_FACTURA,DATATABLE_RESTAURANTE} from "../Componentes/Datatables";
export const Factura = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ref = useRef(null);
  var enableTab = true;
  var subtotal = 0;
  var itbms = 0;
  var total = 0;
  const { usr_print,usr_id,usr_nombre,usr_precio, usr_perfil } = useContext(StoreContext);
  const [error, setError] = useState("");
  const inputUpc =useRef(null);
  const inputCantidad =useRef(null);
  const [caja_num, setCajaNum] = useState("1");
  const [errorCliente, setErrorCliente] = useState("");
  const [errorAnulacion, setErrorAnulacion] = useState("");
  const [error_upc, setError_upc] = useState("");
  const [isModalPagar, setModalPagar] = useState(false);
  const [isModalAnular, setModalAnular] = useState(false);
  const [isModalCopiaF, setModalCopiaF] = useState(false);
  const [isModalCotizacion, setModalCotizacion] = useState(false);
  const [isModalCreditos, setModalCreditos] = useState(false);
  const [isModalEnviado, setModalEnviado] = useState(false);
  const [isModalCliente, setModalCliente] = useState(false);
  const [isAddmodalCliente, setModalAddCliente] = useState(false);
  const [isAddmodalItems, setModalItems] = useState(false);
  const [dataCliente, setDataCliente] = useState([]);
  const [dataCreditos, setDataCreditos] = useState([]);
  const [dataCreditosDetalle, setDataCreditosDetalle] = useState([]);
  const [dataCreditosPago, setDataCreditosPago] = useState([]);
  const [dataFormaPago, setFormaPago] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [dataItemsRestaurante, setDataItemsRestaurante] = useState([]);
  const [dataCategoria, setDataCategoria] = useState([]);
  const [dataRestaurante, setDataRestaurante] = useState([]);
  const [dataOrdenesDetalle, setDataOrdenesDetalle] = useState([]);
  const [dataMesas, setDataMesas] = useState([]);
  const [isResEditing, setResEditing] = useState(false);
  const [formRestaurante, setFormRestaurante] = useState({
    res_nombre:"",
    res_mesa_selected:"",
    res_mesa_id:"",
    res_categoria_selected:"",
    res_categoria_id:""
  });
  const [dataListCopia, setDataListFCopia] = useState([]);
  const [dataFacturaDetalle, setOBJFacturaDetalle] = useState([]);
  const [basicModalEditCliente, setBasicModalEditCliente] = useState(false);
  const [dataListPago, setDatalistpago] = useState([]);
  const [dataListAnulacion, setDatalistAnulacion] = useState([]);
  const [dataListCotizacion, setDataListCotizacion] = useState([]);
  const [formAnulacion, setFormAnulacion] = useState(STATES_FAC_FACTURA({staneName:"formAnulacion",usr_id}));
  const [formListPago, setFormListPago] = useState(STATES_FAC_FACTURA({staneName:"formListPago",usr_id}));
  const [dataCompania, setDataCompania] = useState(STATES_FAC_FACTURA({staneName:"dataCompania",usr_id}));
  const [formClientes, setFormClientes] = useState(STATES_FAC_FACTURA({staneName:"formClientes",usr_id}));
  const [formPagoCredito, setFormPagoCredito] = useState(STATES_FAC_FACTURA({staneName:"formPagoCredito",usr_id}));
  const [formDropDownCliente, setFormDropDownCliente] = useState(STATES_FAC_FACTURA({staneName:"formDropDownCliente",usr_id}));
  const [formDropDownFPago, setFormDropDownFPago] = useState(STATES_FAC_FACTURA({staneName:"formDropDownFPago",usr_id}));
  const [formCredito, setFormCredito] = useState(STATES_FAC_FACTURA({staneName:"formCredito",usr_id}));
  const [formPrecio, setFormPrecio] = useState(STATES_FAC_FACTURA({staneName:"formPrecio",usr_id}));
  const [formFactura, setFormFactura] = useState(STATES_FAC_FACTURA({staneName:"formFactura",usr_id}));
  const [formFacturaDetalle, setFormFacturaDetalle] = useState(STATES_FAC_FACTURA({staneName:"formFacturaDetalle",usr_id}));
  const [formOrdenesDetalle, setFormOrdenesDetalle] = useState(STATES_FAC_FACTURA({staneName:"formOrdenesDetalle",usr_id}));

  useScanDetection({
    onComplete: (code)=>{
      if(document.activeElement !== document.getElementById('art_upc') && document.activeElement !== document.getElementById('fcd_cantidad') && document.activeElement !== document.getElementById('art_descripcion') ){
        if(enableTab===false){
          setTimeout(() => {
            setError_upc("");
            fetchArticuloByUpc({art_upc:code,barcode:true});
            setFormFacturaDetalle({...formFacturaDetalle, art_upc:code});
          }, 300);
        }
     }
    },
    minLength: 3 
  });

  async function fetchRestaurante() {
    var response = await get_res_restaurante();
    setDataRestaurante(response);
  }

  async function fetchMesas() {
    var response = await get_ord_ordenes_mesas();
    setDataMesas(response);
  }

  async function fetchOrdenes(res_id) {
    var defaultCliente = dataCliente.find((data)=>{
      if(data.cli_id==="16"){
        return data;
      }
    })
    setFormClientes(defaultCliente);
    var response = await get_ord_ordenes(res_id);
    if(response[0] !== undefined){
      setResEditing(true)
    }
    else{
      setResEditing(false)
    }
    setDataOrdenesDetalle(response);
    fetchMesas()
  }
  const onClickUpdateOrdenes_sts = () => {
    var body = {
      ord_mesa: formRestaurante.res_mesa_id
    };
    var result = put_sts_ordenes(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchOrdenes(formRestaurante.res_mesa_id);
      else
        setError("ERROR "+data);
    });
  };

  async function fetchCotizacionList() {
    var response = await get_cot_cotizacion_list();
    setDataListCotizacion(response);
    setModalCotizacion(true);
  }
  
  async function fetchCrCredito() {
    var response = await get_cr_credito();
    setDataCreditos(response);
  }

  async function fetchCrCreditoDetalle({crd_cliente}) {
    var response = await get_cr_credito_detalle(crd_cliente);
    setDataCreditosDetalle(response);
  }
  
  async function fetchCreditoDetalleIdentificador({crd_fac_identificador}) {
    var response = await get_cr_credito_detalle_byIdentificador(crd_fac_identificador);
    setFormPagoCredito({...formPagoCredito,
      crd_fecha:response[0].fac_fecha,
      crd_monto:response[0].crd_monto_c,
      crp_monto:response[0].crp_monto_p,
      fac_id:response[0].fac_id,
      fac_identificador:response[0].fac_identificador
    });
  }

  async function fetchCreditoPago({crd_fac_identificador}) {
    var response = await get_crp_credito_pago(crd_fac_identificador);
    setDataCreditosPago(response);
  }

    async function fetchCreditoPagoPrint({crd_fac_identificador,recibe_number,totalValorRecibido,Objfactura_fpago,ObjFPago,montoEntregado}) {
      var response = await get_crp_credito_pago(crd_fac_identificador);
      var resultCP= response.map((data)=>{
        return {
          crp_id:data.crp_id.toString().padStart(10, "0"),
          crp_fecha: moment(data.crp_fecha).format("YYYY-MM-DD") ,
          crp_monto:data.crp_monto
        }
      })
      var crp_monto_p = (Number(formPagoCredito.crp_monto)+Number(totalValorRecibido)).toFixed(2);
      var bodyRecibo={
        dataCompany:dataCompania,
        fcfp_factura_fpago:Objfactura_fpago,
        ObjFPago,
        totalValorRecibido:totalValorRecibido.toFixed(2),
        formPagoCredito:{
          cli_ruc:formPagoCredito.cli_ruc,
          cli_name:formPagoCredito.cli_name,
          fac_id:formPagoCredito.fac_id.toString().padStart(10, "0"),
        },
        recibo_number:recibe_number,
        dataCreditosPago:resultCP,
        crp_monto:Number(formPagoCredito.crd_monto).toFixed(2),
        crp_monto_p,
        saldo:(Number(formPagoCredito.crd_monto)-Number(crp_monto_p)).toFixed(2),
        montoEntregado:montoEntregado.toFixed(2),
      }  

      print_recibo_pago(bodyRecibo)
    }

    async function fetchCreditoPagoPrintCopia() {
      var response = dataCreditosPago;
      var resultCP= response.map((data)=>{
        return {
          crp_id:data.crp_id.toString().padStart(10, "0"),
          crp_fecha: moment(data.crp_fecha).format("YYYY-MM-DD") ,
          crp_monto:data.crp_monto
        }
      })
     
      var bodyRecibo={
        dataCompany:dataCompania,
        formPagoCredito:{
          cli_ruc:formPagoCredito.cli_ruc,
          cli_name:formPagoCredito.cli_name,
          fac_id:formPagoCredito.fac_id.toString().padStart(10, "0"),
        },
        dataCreditosPago:resultCP,
        crp_monto:Number(formPagoCredito.crd_monto).toFixed(2),
        crp_monto_p,
        saldo:(Number(formPagoCredito.crd_monto)-Number(crp_monto_p)).toFixed(2),
        isCopia:true
      }  

      print_recibo_pagoCopia(bodyRecibo)
    }

  async function fetchCliente() {
    var response = await get_cli_cliente();
    setDataCliente(response);
    setBasicModalEditCliente(false);
    setModalAddCliente(false);
    setFormClientesBlank()
  }

  async function fetchListFacImprimir() {
    var response = await get_list_fac_imprimir();
    setDataListFCopia(response)
  }

  async function fetchListAnulacion() {
    var response = await get_list_anulacion();
    setDatalistAnulacion(response)
  }

  async function fetchReportX() {
    var response = await get_report_x({fac_fecha:moment(new Date()).format("YYYY-MM-DD"),fac_caja:caja_num,fac_vendedor:usr_id});
    print_report_x(response);
  }

  async function fetch_list_items() {
    var response = await get_list_items();
    setDataItems(response);

    var itemRestaurante =response.filter((data)=>{
      if(data.art_restaurante ==="1"){
        return data
      }
    })
    setDataItemsRestaurante(itemRestaurante);
    var categoria = [];
    var hashCategoria = {}
    categoria = itemRestaurante.filter(o => hashCategoria[o.art_categoria]|| o.art_categoria === null ? false : hashCategoria[o.art_categoria] = true);
    setDataCategoria(categoria)
  } 

  async function fetchFormaPago() {
    var response = await get_frm_pago();
    setFormaPago(response);
  }

  async function fetchCotizacionByIdentificador({cot_identificador}) {
    var response = await get_cot_cotizacion_fac({cot_identificador});
    setOBJFacturaDetalle(response.items); 
    setFormClientes({
        cli_id: response.cliente[0].cli_id,
        cli_suc: response.cliente[0].cli_suc,
        cli_sts: response.cliente[0].cli_sts,
        cli_nombre: response.cliente[0].cli_nombre,
        cli_direccion:response.cliente[0].cli_direccion,
        cli_telefono: response.cliente[0].cli_telefono,
        cli_telefono2: response.cliente[0].cli_telefono2,
        cli_ruc: response.cliente[0].cli_ruc,
        cli_dv:response.cliente[0].cli_dv,
        cli_email:response.cliente[0].cli_email,
      });
    setModalCotizacion(false);
  }

  async function fetchArticuloByUpc({art_upc,barcode}) {
    var response = await get_articulo_by_code(art_upc);
    if (response[0] !== undefined) {
      setFormPrecio({
        art_precio:response[0].art_precio,
        art_precio2:response[0].art_precio2,
        art_precio3:response[0].art_precio3,
      })
      setError_upc("");
      setFormFacturaDetalle({
        ...formFacturaDetalle,
        fcd_articulo: response[0].art_id,
        art_descripcion: response[0].art_descripcion,
        art_upc: response[0].art_upc,
        fcd_precio: response[0].art_precio,
        art_itbm_cod_v:response[0].art_itbm_cod_v,
        art_itbm_venta:response[0].art_itbm_venta,
        fcd_cantidad:"1"
      }); 
      setModalItems(false);
      if(barcode){
        var flag = false;
        var itbms=0;
        var cantidad =0;
        var objItems = dataFacturaDetalle.map((data, i) => {
          if (data.fcd_articulo === response[0].art_id && data.art_descripcion === response[0].art_descripcion) {
            if(response[0].art_precio === data.fcd_precio){
              cantidad = Number(data.fcd_cantidad) + Number(1);
              itbms = (Number(cantidad)*Number(data.fcd_precio))*Number(data.art_itbm_venta);
              flag = true;
              data.fcd_cantidad = Number(cantidad);
              data.fcd_itbms = itbms;
              data.fcd_total = (Number(cantidad)*Number(response[0].art_precio))+Number(itbms);
            }
            return data;
          }
          return data;
        });
  
        if (!flag) {
          itbms = (Number(1)*Number(response[0].art_precio))*Number(response[0].art_itbm_venta);
          setOBJFacturaDetalle([...dataFacturaDetalle, ...[{...{
            ...formFacturaDetalle,
            fcd_articulo: response[0].art_id,
            art_descripcion: response[0].art_descripcion,
            art_upc: response[0].art_upc,
            fcd_precio: response[0].art_precio,
            art_itbm_cod_v:response[0].art_itbm_cod_v,
            art_itbm_venta:response[0].art_itbm_venta,
            fcd_cantidad:"1",
            fcd_itbms:itbms,
            fcd_total:(Number(1)*Number(response[0].art_precio))+(Number(itbms)),
          },

          }]]);
  
        } else {
          setOBJFacturaDetalle(objItems);
        }
        setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle",usr_id}));
      setError("");
      }
      else{
        setTimeout(() => {
          inputCantidad.current.focus();
        }, 200);
        
      }
    } else {
      setError_upc("Articulo no existe : "+art_upc);
  
       setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle",usr_id}));
    }
  }

  async function fetchCompania() {
    var response = await get_compania();
    var data = response[0];
    setDataCompania({
      bod_suc: data.bod_suc,
      com_nombre: data.com_nombre,
      com_ruc: data.com_ruc,
      com_dv: data.com_dv,
      com_direccion: data.com_direccion,
      com_telefono: data.com_telefono,
      com_telefono2: data.com_telefono2,
      com_email: data.com_email,
      com_fax: data.com_fax,
      com_web: data.com_web,
      com_info_interes:data.com_info_interes
    });
  }

  async function fetchCaja_n() {
    var response = await get_caja_n();
    setCajaNum(response.caja_n)
  }

  async function selectItemFCopia({data,tipo}) {//tipo 0 => recibo, tipo 1 =>electronica
    var response = await get_list_itemsFactura(data.fac_identificador);
    var responseFpago = await get_frm_pago_imprimir_fac(data.fac_identificador);
    var flagCredito= false;
    var arrayFpago= [];
    var resultmap = responseFpago.find((data,i)=>{
      if(data.fcfp_number==="01"){
        flagCredito = true
        return data
      }
    })
    if(flagCredito){
      arrayFpago.push(resultmap);
    }

    COPIA_FACTURA({dataFac:response,dataFpago:flagCredito?arrayFpago:responseFpago,dataCompania,dataSelected:data,usr_prints:tipo==="1"?usr_print:"3"})
    setTimeout(() => {
      setModalCopiaF(false)
    }, 200);
  }

  async function addPago({crp_fac_identificador,crp_cliente}) {
    var ObjFPago = [];
    var Objfactura_fpago = [];
    var totalValorRecibido=0;
    var montoEntregado =0;
    var crp_monto=0;
    var crp_monto_p =0;
    var recibe_number;
    if(dataListPago.length>0){
      dataListPago.map((data,i)=>{
        Objfactura_fpago.push({
          fcfp_identificador:crp_fac_identificador,
          fcfp_number:data.list_fpago,
          fcfp_fpago:data.list_fpagoName,
          fcfp_monto:data.list_monto,
          fcfp_unique:crp_fac_identificador+data.list_fpago+"_C"+moment(new Date()).format("mmss"),
          fcfp_credito:"1"
        });
  
        ObjFPago.push({
          formaPagoFact:data.list_fpago,
          descFormaPago:data.list_fpago==="99"?data.list_fpagoName:null,
          valorCuotaPagada:Number(data.list_monto).toFixed(2),
          descFormaPagoR:data.list_fpagoName
        })
        montoEntregado = montoEntregado + Number(data.list_monto);
        totalValorRecibido = totalValorRecibido + Number(data.list_monto);
      });

      var body={
        formPago:{
          crp_fac_identificador:crp_fac_identificador,
          crp_cliente:crp_cliente,	
          crp_monto:totalValorRecibido,	
          crp_fecha:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        },
        fcfp_factura_fpago:Objfactura_fpago
      }  

      var result = post_crp_credito_pago(body);

       
        result.then((data) => {
          if(data !== false){
            recibe_number=data.toString().padStart(10, "0");
            fetchCreditoDetalleIdentificador({crd_fac_identificador:crp_fac_identificador})
            fetchCreditoPago({crd_fac_identificador:crp_fac_identificador})
            setTimeout(() => {
              fetchCreditoPagoPrint({crd_fac_identificador:crp_fac_identificador,recibe_number,crp_fac_identificador,crp_cliente,totalValorRecibido,Objfactura_fpago,ObjFPago,montoEntregado})
            }, 1000);

          }
          return data;
        })

        setTimeout(() => {

        }, 1000);
      setFormCredito({
        crp_id: "",	
        crp_fac_identificador: "",	
        crp_cliente: "",	
        crp_monto: "",	
        crp_fecha: "",	
        crp_status: ""
      });
      setFormDropDownFPago({
        frm_id: "",
        frm_code:"",
        frm_descripcion: ""
      });
      setDataCreditosPago([]);
      setDatalistpago([]);
    }
  }

  useEffect(() => {
    fetchCliente();
    fetchFormaPago();
    fetchCompania();
    fetch_list_items();
    fetchCaja_n();
    fetchRestaurante();
    fetchMesas();
  }, []);

  const handleSaveRow = ({ exitEditingMode, row, values }) => {
    dataFacturaDetalle[row.index] = values;
    dataFacturaDetalle[row.index] = {
        art_upc:row.original.art_upc,
        art_descripcion: values.art_descripcion,
        art_itbm_venta:row.original.art_itbm_venta,
        fcd_articulo:row.original.fcd_articulo,
        fcd_cantidad: parseFloat(values.fcd_cantidad),
        fcd_itbms: (Number(values.fcd_cantidad)*Number(values.fcd_precio))*Number(row.original.art_itbm_venta),
        fcd_precio: values.fcd_precio,
        fcd_total: (Number(values.fcd_cantidad)*Number(values.fcd_precio))*(1+Number(row.original.art_itbm_venta)),
        fcd_descuento:row.original.fcd_descuento,
        fcd_descuento_number:row.original.fcd_descuento_number,
        art_itbm_cod_v:row.original.art_itbm_cod_v,
      };
      setOBJFacturaDetalle([...dataFacturaDetalle]);
      exitEditingMode();
  };
  const handleSaveRowRestaurante = ({ exitEditingMode, row, values }) => {
    if(isResEditing){
      var body ={
        ordd_id:row.original.ordd_id,
        ordd_cantidad:parseFloat(values.ordd_cantidad)
      }
      put_orden_detalle(body)
    }
    else{
      console.log(values)
      console.log(row.original)
      dataOrdenesDetalle[row.index] = values;
      dataOrdenesDetalle[row.index] = {
          art_upc:row.original.art_upc,
          art_descripcion: row.original.art_descripcion,
          art_itbm_venta:row.original.art_itbm_venta,
          ordd_articulo:row.original.ordd_articulo,
          ordd_cantidad: parseFloat(values.ordd_cantidad),
          ordd_itbms: (Number(values.ordd_cantidad)*Number(row.original.ordd_precio))*Number(row.original.art_itbm_venta),
          ordd_precio: row.original.ordd_precio,
          ordd_total: (Number(values.ordd_cantidad)*Number(row.original.ordd_precio))*(1+Number(row.original.art_itbm_venta)),
          ordd_descuento:row.original.ordd_descuento,
          ordd_descuento_number:row.original.ordd_descuento_number,
          art_itbm_cod_v:row.original.art_itbm_cod_v,
        };
        setDataOrdenesDetalle([...dataOrdenesDetalle]);
        exitEditingMode();
    }
  };
  const handleSelectCliente = ( row) => {
    setFormClientes({...row,cli_limitc:(Number(row.cli_limitc)-(Number(row.crd_monto_c)-Number(row.crp_monto_p))).toFixed(2)
      
    });
    setModalCliente(false);
  };

  const onChangeDropdown = (selectedOptions) => {

    if(selectedOptions.objName==="cliente"){
      setFormDropDownCliente({
        ...formDropDownCliente,
        cli_id: selectedOptions.value,
        cli_nombre: selectedOptions.label,
        cli_ruc:selectedOptions.ruc,
        cli_email:selectedOptions.email,
        cli_dv:selectedOptions.dv
      });
    }
    else{
      setFormDropDownFPago({
        ...formDropDownFPago,
        frm_id: selectedOptions.value,
        frm_code:selectedOptions.frm_code,
        frm_descripcion: selectedOptions.label
      });
    }
    setError("");
  };

  function onChangeFac_detail(event) {
    const { value, name } = event.target;
      setFormFacturaDetalle({
        ...formFacturaDetalle,
        [name]: value,
      });
      setError("");
      setErrorCliente("")
      setError_upc("");
  }
  function setPrecio(art_precio) {
    setFormFacturaDetalle({
      ...formFacturaDetalle,
      fcd_precio: art_precio,
    }); 
    setAnchorEl(false)
    setTimeout(() => {
      inputCantidad.current.focus();
    }, 200);
  };

  const deleteRow = (row) => {
    var value = dataFacturaDetalle.filter((data, i) => {
      if (i !== row.index) return data;
    });
    setOBJFacturaDetalle(value);
  };

  const deleteRowRestaurant = (row) => {
    console.log(row)
    if(isResEditing){
      var body ={
        ordd_id:row.original.ordd_id
      }
      put_delete_orden_detalle(body)
      setTimeout(() => {
        fetchOrdenes(formRestaurante.res_mesa_id)
      }, 1000);
     
    }
    else{
      var value = dataOrdenesDetalle.filter((data, i) => {
        if (i !== row.index) return data;
      });
      setDataOrdenesDetalle(value);
    }  
  };

  function addItemRestaurante(dataItem){
    var defaultCliente = dataCliente.find((data)=>{
      if(data.cli_id==="16"){
        return data;
      }
    })
    setFormClientes(defaultCliente);
    setFormOrdenesDetalle({
      ...formOrdenesDetalle,
      ordd_articulo: dataItem.art_id,
      art_descripcion: dataItem.art_descripcion,
      art_upc: dataItem.art_upc,
      ordd_precio: dataItem.art_precio,
      art_itbm_cod_v:dataItem.art_itbm_cod_v,
      art_itbm_venta:dataItem.art_itbm_venta,
      ordd_cantidad:"1"
    }); 

      var flag = false;
      var itbms=0;
      var cantidad =0;
      var objItems = dataOrdenesDetalle.map((data, i) => {
        if (data.ordd_articulo === dataItem.art_id && data.art_descripcion === dataItem.art_descripcion) {
          if(dataItem.art_precio === data.ordd_precio){
            cantidad = Number(data.ordd_cantidad) + Number(1);
            itbms = (Number(cantidad)*Number(data.ordd_precio))*Number(data.art_itbm_venta);
            flag = true;
            data.ordd_cantidad = Number(cantidad);
            data.ordd_itbms = itbms;
            data.ordd_total = (Number(cantidad)*Number(dataItem.art_precio))+Number(itbms);
          }
          return data;
        }
        return data;
      });

      if (!flag) {
        itbms = (Number(1)*Number(dataItem.art_precio))*Number(dataItem.art_itbm_venta);
        setDataOrdenesDetalle([...dataOrdenesDetalle, ...[{...{
          ...formOrdenesDetalle,
          ordd_articulo: dataItem.art_id,
          art_descripcion: dataItem.art_descripcion,
          art_upc: dataItem.art_upc,
          ordd_precio: dataItem.art_precio,
          art_itbm_cod_v:dataItem.art_itbm_cod_v,
          art_itbm_venta:dataItem.art_itbm_venta,
          ordd_cantidad:"1",
          ordd_itbms:itbms,
          ordd_total:(Number(1)*Number(dataItem.art_precio))+(Number(itbms)),
        },

        }]]);

      } else {
        setDataOrdenesDetalle(objItems);
      }
      setFormOrdenesDetalle(STATES_FAC_FACTURA({staneName:"formOrdenesDetalle",usr_id}));
    setError("");
    
  }

  function keyPressCantidad(event) {
    var flag = false;
    var itbms=0;
    var cantidad =0;
    var objItems = dataFacturaDetalle.map((data, i) => {
      if (data.fcd_articulo === formFacturaDetalle.fcd_articulo && data.art_descripcion === formFacturaDetalle.art_descripcion) {
        if(formFacturaDetalle.fcd_precio === data.fcd_precio){
          cantidad = Number(data.fcd_cantidad) + Number(formFacturaDetalle.fcd_cantidad);
          itbms = (Number(cantidad)*Number(data.fcd_precio))*Number(data.art_itbm_venta);
          flag = true;
          data.fcd_cantidad = Number(cantidad);
          data.fcd_itbms = itbms;
          data.fcd_total = (Number(cantidad)*Number(formFacturaDetalle.fcd_precio))+Number(itbms);
        }
        return data;
      }
      return data;
    });

    if (!flag) {
      itbms = (Number(formFacturaDetalle.fcd_cantidad)*Number(formFacturaDetalle.fcd_precio))*Number(formFacturaDetalle.art_itbm_venta);
      setOBJFacturaDetalle([...dataFacturaDetalle, ...[{...formFacturaDetalle,
        fcd_itbms:itbms,
        fcd_total:(Number(formFacturaDetalle.fcd_cantidad)*Number(formFacturaDetalle.fcd_precio))+(Number(itbms)),
      }]]);

    } else {
      setOBJFacturaDetalle(objItems);
    }
    setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle",usr_id}));
  }

  function handleKeyPress(event) {
    const { value, name } = event.target;
        if (event.key === "Enter" && name === "art_upc") {
          if(error_upc.length>0){
            setFormFacturaDetalle({...formFacturaDetalle, art_upc:""});
          }
         
        fetchArticuloByUpc({art_upc:value,barcode:false});
        }
        if (event.key === "Enter" && name === "fcd_cantidad" &&formFacturaDetalle.fcd_cantidad.length<=5 ) {
          keyPressCantidad()
        }
    
    setError("");
  }

  const onClickNuevaFactura = () => {
    var defaultCliente = dataCliente.find((data)=>{
      if(data.cli_id==="16"){
        return data;
      }
    })
    setModalPagar(false);
    setDatalistpago([]);
    setOBJFacturaDetalle([]);
    setFormDropDownCliente(STATES_FAC_FACTURA({staneName:"formDropDownCliente",usr_id}));
    setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle",usr_id}));
    setFormFactura({
      fac_id: "",
      fac_identificador: moment(new Date()).format("YYYYMMDDHHmmss"),
      fac_cliente: "",
      fac_vendedor: usr_id,
      fac_fecha: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      fac_caja: "",
      fac_sts: "",
    });
    setFormDropDownFPago(STATES_FAC_FACTURA({staneName:"formDropDownFPago",usr_id}));
    setFormListPago(STATES_FAC_FACTURA({staneName:"formListPago",usr_id}));
    setFormClientes(defaultCliente);
    setError_upc("");
    setError("");
    setErrorAnulacion("");
    setErrorCliente("");
    // setTimeout(() => {
    //   inputUpc.current.focus();
    // }, 1000);
  };
  
  const onClickGuardarOrden = () => {//tipo 0 => recibo, tipo 1 =>electronica
    if (formRestaurante.res_mesa_selected > 0 && dataOrdenesDetalle[0] !== undefined) {
      SAVE_ORDEN({dataFac:dataOrdenesDetalle,formFactura,formRestaurante})
      setTimeout(() => {
        fetchOrdenes(formRestaurante.res_mesa_id)
      }, 1000);
    }
  };
  const onClickSaveFactura = ({tipo}) => {//tipo 0 => recibo, tipo 1 =>electronica
    if (formClientes.cli_id.length > 0 && dataFacturaDetalle[0] !== undefined) {
      SAVE_FACTURA({dataFac:dataFacturaDetalle,dataFpago:dataListPago,dataCompania,formFactura,formDropDownFPago,formClientes,usr_prints:tipo==="1"?usr_print:"3"})
      setModalEnviado(true)
      setModalPagar(false);
        setTimeout(() => {
          setModalEnviado(false);
          onClickNuevaFactura()
        }, 7000);
    } else {
      setError("Campos vacios");
    }
  };

  const onClickAnular = ({tipo}) => {//tipo 0 => recibo, tipo 1 =>electronica
    var result= ANULAR_FACTURA({dataCompania,formAnulacion,formClientes,usr_prints:tipo==="1"?usr_print:"3",formDropDownFPago})
     if(!result){
       setErrorAnulacion("Escriba el motivo de la anulación.")
     }
     else{
      setModalAnular(false);
      setErrorAnulacion("")
     }
   };

  const onClickOpenPagar= () => {
    setModalPagar(true);
    setFormListPago(STATES_FAC_FACTURA({staneName:"formListPago",usr_id}));
    setFormDropDownFPago({
      frm_id: "",
      frm_code:"",
      frm_descripcion: ""
    });
    var limitc=0;
     dataListPago.find((data, i) => {
      if (data.list_fpago === "01"){
        limitc=data.list_monto;
        return data
      } 
    });
    setFormClientes({...formClientes,cli_limitc:Number(formClientes.cli_limitc)+Number(limitc)})
    setDatalistpago([]);
  }

  const onClickSaveCliente = () => {
    var body = {
      cli_nombre: formClientes.cli_nombre,
      cli_direccion: formClientes.cli_direccion,
      cli_telefono: formClientes.cli_telefono,
      cli_telefono2: formClientes.cli_telefono2,
      cli_ruc: formClientes.cli_ruc,
      cli_dv: formClientes.cli_dv,
      cli_email: formClientes.cli_email,
      cli_limitc: formClientes.cli_limitc,
      cli_credito: formClientes.cli_credito,
    };
    if (formClientes.cli_nombre.length > 0) {
      if(formClientes.cli_email.length>0){
        if(validate(formClientes.cli_email)){
          if(FIND_DESC(dataCliente,formClientes.cli_ruc,null)){
            setErrorCliente("Error: Ruc ya esta registrado");
          }
          else{
            post_cli_cliente(body);
            setTimeout(() => {
              fetchCliente();
            }, 1000);
          }
        }
        else{
          setErrorCliente("E-Mail incorrecto");
        }
      }
      else{
        if(FIND_DESC(dataCliente,formClientes.cli_ruc,null)){
          setErrorCliente("Error: Ruc ya esta registrado");
        }
        else{
          post_cli_cliente(body);
          setTimeout(() => {
            fetchCliente();
          }, 1000);
        }
      }
    } else {
      setErrorCliente("Campos importantes estan vacios");
    }
  };
  
  function onChangeAnulacion(event) {
    const { value, name } = event.target;
    setFormAnulacion({
      ...formAnulacion,
      [name]: value,
    });
    setErrorAnulacion("")
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormClientes({
      ...formClientes,
      [name]: value,
    });
    setErrorCliente("");
  }

  function onChangeFpago(event) {
    const { value, name } = event.target;
    setFormListPago({
      ...formListPago,
      [name]: value,
    });
    setError("");
  }

  const setFormClientesBlank = () => {
    setFormClientes(STATES_FAC_FACTURA({staneName:"formClientes",usr_id}))
  }

  const setAddFPago = () => {
    if(formDropDownFPago.frm_code.length>0){
      var flag= false;
      var flagLimitC= false;
      var sum;
      var listMonto=0;
     sum = dataListPago.map((data)=>{
      listMonto = Number(data.list_monto)+Number(formListPago.list_monto);
        if(data.list_fpago===formDropDownFPago.frm_code){
          flag = true;
         
          if(formDropDownFPago.frm_code==="01"){
            if(Number(formListPago.list_monto) <= Number(formClientes.cli_limitc)){
              flagLimitC=true;
              setFormClientes({...formClientes,cli_limitc:Number(formClientes.cli_limitc)-Number(formListPago.list_monto)})
            }
            else{
              var flagLimitC= false;
              setError("Monto supera el límite de crédito");
            }
          }else{
            var flagLimitC= true;
          }
          if(flagLimitC){
            return{
              list_identificador: formFactura.fac_identificador,
              list_fpago: formDropDownFPago.frm_code,
              list_fpagoName: formDropDownFPago.frm_descripcion,
              list_monto:listMonto,
            };
          }
        }
        else{
          if(Number(listMonto) <= Number(formClientes.cli_limitc)){
            return data
          }
        }
      });
      if(flag == false){
        if(formDropDownFPago.frm_code==="01"){
          if(Number(formListPago.list_monto)<= Number(formClientes.cli_limitc)){
            setFormClientes({...formClientes,cli_limitc:Number(formClientes.cli_limitc)-Number(formListPago.list_monto)})
            setDatalistpago([...dataListPago, ...[{
              list_identificador: formFactura.fac_identificador,
              list_fpago: formDropDownFPago.frm_code,
              list_fpagoName: formDropDownFPago.frm_descripcion,
              list_monto: Number(formListPago.list_monto),
            }]]);
          }
          else{
            setError("Monto supera el límite de crédito");
          }
        }
        else{
          setDatalistpago([...dataListPago, ...[{
            list_identificador: formFactura.fac_identificador,
            list_fpago: formDropDownFPago.frm_code,
            list_fpagoName: formDropDownFPago.frm_descripcion,
            list_monto: Number(formListPago.list_monto),
          }]]);
        }

      }
      
      else{
        setDatalistpago(sum)
      }

      setFormListPago(STATES_FAC_FACTURA({staneName:"formListPago",usr_id}))
      setFormDropDownFPago({
        frm_id: "",
        frm_code:"",
        frm_descripcion: ""
      })
    }
    else{
      setError("Selecciona la forma de pago");
    }
  }

  const printAbono=({crp_fac_identificador})=>{
    
  }
  const deleteCrePago = (row) => {
    var body={
      crp_id:row.original.crp_id,
      crp_fecha_delete:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    }
    delete_crp_credito_pago(body)
    setTimeout(() => {
      fetchCreditoPago({crd_fac_identificador:row.original.crp_fac_identificador})
      fetchCreditoDetalleIdentificador({crd_fac_identificador:row.original.crp_fac_identificador})
    }, 1000);
  };

  const deleteRowFpago = (row) => {
    if(row.original.list_fpago==="01"){
      setFormClientes({...formClientes,cli_limitc:Number(formClientes.cli_limitc)+Number(row.original.list_monto)})
    }
      var value = dataListPago.filter((data, i) => {
        if (i !== row.index) return data;
      });
      setDatalistpago(value);
  };
  
  const onClickUpdateClient = () => {
    if (formClientes.cli_nombre.length > 0) { 
      if(formClientes.cli_email.length>0){
        if(validate(formClientes.cli_email)){
          if(FIND_DESC(dataCliente,formClientes.cli_ruc,formClientes.cli_id)){
            setErrorCliente("Error: Ruc ya esta registrado");
          }
          else{
            PUT_CLIENTE({formClientes})
            setTimeout(() => {
              fetchCliente();
            }, 1000);
          }
        }
        else{
          setErrorCliente("E-Mail incorrecto");
        }
      } else {
        if(FIND_DESC(dataCliente,formClientes.cli_ruc,formClientes.cli_id)){
          setErrorCliente("Error: Ruc ya esta registrado");
        }
        else{
          PUT_CLIENTE({formClientes})
          setTimeout(() => {
            fetchCliente();
          }, 1000);
        }
      }
      }
      else{
        setErrorCliente("Campos importantes estan vacios");
      }
  };

  var ObjClientes = [];
  dataCliente.map((item) => {
    ObjClientes.push({
      value: item.cli_id,
      label: item.cli_nombre + " " + item.cli_ruc,
      ruc: item.cli_ruc,
      email: item.cli_email,
      dv:item.cli_dv,
      objName: "cliente",
    });
  });

  var ObjFrmPago = [];
  var icon="";
  dataFormaPago.map((item) => {
    if(item.frm_code==="01"){
      icon = "hand-holding-usd";
    }
    if(item.frm_code==="02"){
      icon = "money-bill-alt";
    }
    if(item.frm_code==="03"){
      icon = "cc-visa";
    }
    if(item.frm_code==="04"){
      icon ="credit-card";
    }
    if(item.frm_code==="08"){
      icon = "share-square";
    }
    if(item.frm_code==="99"){
      icon ="yappy"
    }
    if(formClientes.cli_credito==="1"){
      ObjFrmPago.push({
        value: item.frm_id,
        frm_code:item.frm_code,
        label: item.frm_descripcion,
        objName: "frmPago",
        icon:icon
      });
    }
    else{
      if(item.frm_code !=="01"){
        ObjFrmPago.push({
          value: item.frm_id,
          frm_code:item.frm_code,
          label: item.frm_descripcion,
          objName: "frmPago",
          icon:icon
        });
      }
    }
  });

  if (formClientes.cli_nombre.length > 0) {
    enableTab = false;
  }

  dataFacturaDetalle.map((row) => {
    subtotal = Number(subtotal) + Number(Number(row.fcd_cantidad) * Number(row.fcd_precio));
    itbms = Number(itbms) + roundNumber(Number(row.fcd_itbms),3);
    total =   Number(subtotal) + Number(itbms);
  });
  subtotal = roundNumber(subtotal,2);
  itbms = roundNumber(itbms,2);
  total = roundNumber(total,2);

  const arrayReverseObj = (obj) => {
    let newArray = []
    Object.keys(obj)
      .sort()
      .reverse()
      .forEach(key => {
        newArray.push( obj[key])
      })
    return newArray  
  }
  $(document).ready(function() {
    usr_perfil !=="3" &&  $('.focus_factura .MuiTableContainer-root').animate({
        scrollTop: $('.MuiTableContainer-root').get(0).scrollHeight
    }, 600);
});
const handleOnChangeCheckBox = (event) => {
  const { value, name } = event.target;
  setFormClientes({
    ...formClientes,
    [name]:value==="0"?"1":"0"
})
};
var crp_monto=0;
var crp_monto_p =0;
dataCreditosPago.map((data,i)=>{
  crp_monto= Number(crp_monto)+ Number(data.crp_monto)
}
)
dataCreditosDetalle.map((data,i)=>{
  crp_monto_p= Number(crp_monto_p)+ Number(data.crp_monto_p)
})

function onChangeCredPago(event) {
  const { value, name } = event.target;
  setFormCredito({
    ...formCredito,
    [name]: value,
  });
  setError("");
}

  document.getElementsByClassName("css-sghohy-MuiButtonBase-root-MuiButton-root").innerHTML = "Lecciones"
  // var mesas = [];
  // for (var i = 1; i < Number(dataRestaurante.res_mesas)+1; i++) {
  //   mesas.push({
  //     id:i,
  //     label:"Mesa "+ Number(i),
  //     selected:false
  //   });
  // }

  const handleOnChangeMesas = (select) => {

  //   setFormClientes({
  //     ...formClientes,
  //     [name]:value==="0"?"1":"0"
  // })
  };

   console.log(dataOrdenesDetalle)
   console.log(formRestaurante)
  //  var 
  return (<>
  {usr_perfil==="3"?<>
  <Container fluid>
  <Row>
    <Col xs={12} className="div-top text-center">
    <Row>
      <Col xs={12}>
      <Label>ORDENES</Label>
      </Col>
    </Row>
    </Col>
    <Col xs={12} className="text-center div-mesas">
    {
      
  dataRestaurante.map((data)=>{
    return  <MDBBtn  className={formRestaurante.res_mesa_selected===data.res_id?"mesas-btn p-3  bg-secondary bg-gradient text-white rounded-5":"mesas-btn p-3 bg-primary bg-gradient text-white rounded-5"} 
    onClick={() => { fetchOrdenes(data.res_id);setFormRestaurante({...formRestaurante,res_mesa_selected:data.res_id,res_mesa_id:data.res_id,res_nombre:data.res_nombre,res_categoria_selected:"",res_categoria_id:""})}}>
     <Label>{data.res_nombre}</Label>
     {
      dataMesas.map((dataMe)=>
      {
        if(dataMe.ord_mesa=== data.res_id){
          return <img src={KNIFE_IMAGE} className="src-image" alt="..." />
        }
      })
     }
    </MDBBtn>
  })
}
    </Col>
    {
      formRestaurante.res_mesa_selected.length>0?<>

    <Col xs={12} className="div-container">
    <Row>
      <Col xs={12} className="text-center">
        <Label className="title-res">{formRestaurante.res_nombre}</Label>
      </Col>
      <Col xs={3} className="div-categoria">
      {
          dataCategoria.map((data)=>{
            return  <MDBBtn  className={formRestaurante.res_categoria_id===data.art_categoria?"categoria-btn p-3  bg-secondary bg-gradient text-white rounded-5":"categoria-btn p-3 bg-primary bg-gradient text-white rounded-5"}
            onClick={() => {setFormRestaurante({...formRestaurante,res_categoria_selected:data.cat_descripcion,res_categoria_id:data.art_categoria})}}>
             <Label>{data.cat_descripcion}</Label>
             {/* <img src={KNIFE_IMAGE} className="src-image" alt="..." /> */}
            </MDBBtn>
          })
      }
      </Col>
      <Col  xs={9} className="div-items">
        <Row className="background-div-item">
        {
            dataItemsRestaurante.map((data)=>{
              if(data.art_categoria===formRestaurante.res_categoria_id)
              return <Col xs={4} className="mb-2 mt-2">
              <MDBCard alignment='center'>
              <MDBCardBody>
                <MDBCardTitle>{data.art_img==="1"? <img className="img-art-restaurante"  src ={imgURLocalImage+data.art_imgName} />:<img src={NO_IMAGE} className="img-art-restaurante" alt="..." />} </MDBCardTitle>
                <MDBCardText>{data.art_descripcion}</MDBCardText>
                <MDBBtn className="btn-price-restaurante" onClick={() => {addItemRestaurante(data);}}>{"B/."+data.art_precio}</MDBBtn>
              </MDBCardBody>
            </MDBCard>
              </Col> 
            })
        }
        </Row>
      </Col>
      <Col xs={12} className="mt-3">
          <Row>
            <Col xs={8}>
            </Col>
            <Col xs={2}>
              <MDBBtn className="d-flex float-end btn-cancelar-position" disabled ={formRestaurante.res_mesa_selected.length>0 && dataOrdenesDetalle[0] != undefined ?false:true} onClick={() => ( onClickUpdateOrdenes_sts())} color="danger">
                <MDBIcon fas icon="ban" className="me-1 class-btn-factura d-flex" />  Cancelar
              </MDBBtn>
            </Col>
            <Col xs={2}>
              <MDBBtn className="d-flex float-end"  disabled ={dataOrdenesDetalle[0] != undefined && isResEditing ===false ? false:true} onClick={() =>  (dataOrdenesDetalle[0] != undefined ? onClickGuardarOrden():null) }>
                <MDBIcon fas icon="save" className="me-1 class-btn-factura d-flex"  />    Guardar
              </MDBBtn>
            </Col>
          </Row>
      </Col>
      <Col className="modal-add-inventario datatable-factura focus_factura mt-2">
        <DATATABLE_RESTAURANTE deleteRow={deleteRowRestaurant} handleSaveRow={handleSaveRowRestaurante} dataOrdenes={arrayReverseObj(dataOrdenesDetalle)} 
        enableTab={enableTab} usr_precio={usr_precio} isRestaurant={true} isEditing={isResEditing}/>
      </Col>

    </Row>
    <Row className="mt-3 footer-restaurante">
      <FOOTER_FACTURA enableTab = {enableTab} subtotal = {subtotal} itbms = {itbms} total ={total}/>
      </Row>
    </Col>    
      </>:<><img src={LOGO} className="cash-box-img-restaurante" alt="..." /></>
    }

  </Row>

  </Container>

  
  </>: <Container className="bg-light border border-0" fluid>
      {isModalEnviado && (
        <EnviadoModal
        isOpen={isModalEnviado}
        toggle={setModalEnviado}
        dataListpago={dataListPago}
        total ={total}
        />
      )}
      {isModalCliente && (
        <ListaClienteModal
        dataCliente={dataCliente}
        isOpen={isModalCliente}
        toggle={setModalCliente}
        selectCliente={handleSelectCliente}
        />
      )}
      {isAddmodalCliente && (
        <ClienteModal
          props={formClientes}
          isOpen={isAddmodalCliente}
          basicModalEdit={basicModalEditCliente}
          toggle={setModalAddCliente}
          toggleSave={onClickSaveCliente}
          toggleSaveEdit={onClickUpdateClient}
          onChange={onChange}
          error={errorCliente}
          setFormClientesBlank={setFormClientesBlank}
          handleOnChangeCheckBox={handleOnChangeCheckBox}
        />
      )}
      {isModalAnular && (
        <AnulacionModal
          dataListAnulacion={dataListAnulacion}
          formAnulacion={formAnulacion}
          isOpen={isModalAnular}
          toggle={setModalAnular}
          onChange={onChangeAnulacion}
          setFormAnulacion={setFormAnulacion}
          toggleSave={onClickAnular}
          errorAnulacion={errorAnulacion}
        />
      )}
      {isAddmodalItems && (
        <ListaItemModal
          props={dataItems}
          isOpen={isAddmodalItems}
          toggle={setModalItems}
          selectItem={fetchArticuloByUpc}
        />
      )}
      {isModalCotizacion && (
        <ListaCotizacion
          dataListCotizacion={dataListCotizacion}
          isOpen={isModalCotizacion}
          toggle={setModalCotizacion}
          selectItemCotizacion={fetchCotizacionByIdentificador}
        />
      )}
      {isModalCopiaF && (
        <ListaFacModal
          dataListCopia={dataListCopia}
          isOpen={isModalCopiaF}
          toggle={setModalCopiaF}
          selectItemFCopia={selectItemFCopia}
        />
      )}
      {isModalPagar && (
        <PagarModal
          deleteRow={deleteRowFpago}
          setDatalistpago={setDatalistpago}
          formDropDownFPago={formDropDownFPago}
          setAddFPago ={setAddFPago}
          onChangeDropdown={onChangeDropdown}
          dataListpago={dataListPago}
          formListPago={formListPago}
          ObjFrmPago={ObjFrmPago}
          isOpen={isModalPagar}
          toggle={setModalPagar}
          toggleSave={onClickSaveFactura}
          onChange={onChangeFpago}
          total={total}
          error={error}
          formClientes={formClientes}
          setFormClientes={setFormClientes}
        />
      )}
      {isModalCreditos && (
        <ListaCreditos
          dataListCreditos={dataCreditos}
          isOpen={isModalCreditos}
          toggle={setModalCreditos}
          selectCreditosDetalle={fetchCrCreditoDetalle}
          dataListCreditoDetalle={dataCreditosDetalle}
          dataListCreditoPago={dataCreditosPago}
          selectCreditosPago={fetchCreditoPago}
          onChangeCredPago={onChangeCredPago}
          formCredito={formCredito}
          addPago={addPago}
          crp_monto={crp_monto}
          deleteCrePago={deleteCrePago}
          crp_monto_p={crp_monto_p}
          printAbono={fetchCreditoPagoPrintCopia}
          ObjFrmPago={ObjFrmPago}
          onChangeDropdown={onChangeDropdown}
          formDropDownFPago={formDropDownFPago}
          onChange={onChangeFpago}
          formListPago={formListPago}
          setAddFPago={setAddFPago}
          dataListpago={dataListPago}
          setDatalistpago={setDatalistpago}
          deleteRow={deleteRow}
          setFormDropDownFPago={setFormDropDownFPago}
          setFormListPago= { setFormListPago}
          deleteRowFpago={deleteRowFpago}
          setFormPagoCredito={setFormPagoCredito}
          formPagoCredito={formPagoCredito}
          printFac={selectItemFCopia}
        />
      )}
      <Row className="mt-2">
        <Col xs={12} className="text-center">
          <Label className="font-weight-bold-title">FACTURA</Label>
        </Col>
        <Col xs={4}>
          <MDBInput
            id="fac_vendedor"
            wrapperClass="mb-3"
            label="Vendedor"
            name="fac_vendedor"
            defaultValue={usr_nombre}
            disabled={true}
          />
        </Col>
        <Col xs={8}>
        <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>fetchReportX()}>Informe X</Button>
        <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{setModalAnular(true); fetchListAnulacion(); setErrorAnulacion("")}}>Anulación</Button>
        <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{setModalCopiaF(true);fetchListFacImprimir()}}>Copia</Button>
        <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{fetchCotizacionList()}}>Cotizaciones</Button>
        <Button className=" btn-reporte_x button-color-dark" variant="contained" onClick={()=>{fetchCrCredito();setModalCreditos(true)}}>Créditos</Button>
        <Button className=" btn-reporte_x button-color-gray" variant="contained" disabled={true}>Apartados</Button>
        <img src={LOGO} className="logo-factura" alt="..." />
        </Col>
        <Col xs={6} className="d-flex">
        <MDBInput
            id="cli_ruc"
            wrapperClass="mb-3"
            label="CLIENTE"
            name="cli_ruc"
            value={formClientes.cli_nombre + " " +formClientes.cli_dv}
            disabled={true}
          />
          <img src={SEARCH_IMAGE} className="src-image" alt="..." onClick={()=>{setModalCliente(true);setErrorCliente("");
          setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle",usr_id}));}}/>
          <img src={ADD_IMAGE} className="src-image" alt="..." onClick={()=>{setModalAddCliente(true);setErrorCliente("");setFormClientes(STATES_FAC_FACTURA({staneName:"formClientes",usr_id}))}}/>
          {formClientes.cli_nombre.length>0 && formClientes.cli_nombre !=="CONSUMIDOR FINAL"?<img src={EDIT_IMAGE} className="src-image" alt="..." onClick={()=>{setModalAddCliente(true);;setErrorCliente("");setBasicModalEditCliente(true)}}/>:null}
        </Col>
        <Col xs={6}>
        <MDBInput
            id="cli_email"
            wrapperClass="mb-3"
            label="EMAIL"
            name="cli_email"
            value={formClientes.cli_email}
            disabled={true}
          />
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={2} className="d-flex col-factura-items">
              <MDBInput
                id="art_upc"
                className="height-37"
                label="Código"
                name="art_upc"
                onChange={onChangeFac_detail}
                disabled={enableTab}
                value={formFacturaDetalle.art_upc}
                ref={inputUpc}
          
                onKeyDown={(e) => {
                  handleKeyPress(e, formFactura.fcd_articulo);
                }}
              />
                  {formClientes.cli_nombre.length>0?<img src={SEARCH_IMAGE} className="src-image" alt="..." onClick={()=>{setModalItems(true)}}/>:null}
                  {formClientes.cli_nombre.length>0 && formFacturaDetalle.art_descripcion.length>0?<img src={EXIT_IMAGE} className="src-image" alt="..." 
                  onClick={()=>{      setFormFacturaDetalle(STATES_FAC_FACTURA({staneName:"formFacturaDetalle",usr_id}));}}/>:null}
            </Col>
            <Col xs={6} className="col-factura-items">
              <MDBInput
                id="art_descripcion"
                label="Descripción artículo"
                name="art_descripcion"
                onChange={onChangeFac_detail}
                value={formFacturaDetalle.art_descripcion}
                disabled={formFacturaDetalle.fcd_articulo.length>0  && usr_precio ==="1"?false:true}
              />
            </Col>
            <Col xs={2} className="col-factura-items d-flex">
              <MDBInput
                id="fcd_precio"
                label="Precio"
                name="fcd_precio"
                type={"number"}
                min={"1"}
                value={formFacturaDetalle.fcd_precio}
                onChange={onChangeFac_detail}
                disabled={formFacturaDetalle.fcd_articulo.length>0 && usr_precio ==="1"?false:true}
              />
               
                    <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled ={formFacturaDetalle.art_upc.length>0  ?false:true}
      >
      <img src={PRECIO_IMAGE} className="src-image style-precio-btn" alt="..." 
       />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=>setPrecio(formPrecio.art_precio)}>{formPrecio.art_precio}</MenuItem>
        <MenuItem onClick={()=>setPrecio(formPrecio.art_precio2)}>{formPrecio.art_precio2}</MenuItem>
        <MenuItem onClick={()=>setPrecio(formPrecio.art_precio3)}>{formPrecio.art_precio3}</MenuItem>
      </Menu>
            </Col>
            <Col xs={2} className="col-factura-items d-flex">
              <MDBInput
                id="fcd_cantidad"
                label="Cantidad"
                name="fcd_cantidad"
                ref={inputCantidad}
                type={"number"}
                min={"1"}
                max={10000}
                // ref={ref}
                step='0.01'
                disabled ={formFacturaDetalle.art_descripcion.length>0  ?false:true}
                onChange={onChangeFac_detail}
                value={formFacturaDetalle.fcd_cantidad}
                onKeyDown={(e) => handleKeyPress(e, formFacturaDetalle.fcd_cantidad)}
              />
              {formFacturaDetalle.fcd_cantidad !=="0"?<img src={ADD_IMAGE} className="src-image" alt="..." 
                  onClick={()=>{keyPressCantidad();}}/>:<img src={ADD_IMAGE} className="src-image" alt="..." />}
              
            </Col>
            <Col xs={8}>
              <Label className="error-label">{error_upc.length > 0 ? error_upc : "="}</Label>
              <Label className="error-label">{error.length > 0 && isAddmodalCliente === false ? <>{" * "+ error}</> : ""}</Label>
            </Col>
            <Col xs={4} className="mb-2">
              <Row>
                <Col xs={4}> 
                  <MDBBtn onClick={() => ( onClickNuevaFactura())} className="d-flex float-end" color="success">
                    <MDBIcon fas icon="plus-circle" className="me-1 class-btn-factura" /> Nuevo
                  </MDBBtn>
                </Col>
                <Col xs={4}>  
                  <MDBBtn className="d-flex float-end btn-cancelar-position" disabled ={formClientes.cli_nombre.length>0?false:true} onClick={() => ( onClickNuevaFactura())} color="danger">
                    <MDBIcon fas icon="ban" className="me-1 class-btn-factura d-flex" />  Cancelar
                  </MDBBtn></Col>
                <Col xs={4}>   
                  <MDBBtn className="d-flex float-end"  disabled ={dataFacturaDetalle[0] != undefined ?false:true} onClick={() =>  (dataFacturaDetalle[0] != undefined ? onClickOpenPagar():null) }>
                    <MDBIcon fas icon="credit-card" className="me-1 class-btn-factura d-flex"  />     Pagar
                  </MDBBtn>
                </Col>
              </Row>
            </Col>
            <Col className="modal-add-inventario datatable-factura focus_factura">
              <DATATABLE_FACTURA deleteRow={deleteRow} handleSaveRow={handleSaveRow} dataFacturaDetalle={arrayReverseObj(dataFacturaDetalle)} enableTab={enableTab} usr_precio={usr_precio} isRestaurant={false} />
            </Col>
          </Row>
          <Row className="mt-3">
            <FOOTER_FACTURA enableTab = {enableTab} subtotal = {subtotal} itbms = {itbms} total ={total}/>
          </Row>
        </Col>
      </Row>
    </Container>}
 
  </>

  );
};